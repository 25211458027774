@import "../../commons/colors.scss";

.center {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50vh;
}

.circle {
    border: 2px solid;
    max-height: 50vh;
    background: $primary_blue;
    box-shadow: 10px 10px 5px $lightBlue;
    border-radius: 50%;
}

.transitionCircle {
    width: 201px;
    height: 200px;
    position: fixed;
    background: $primary_blue;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.transitionCircle::before {
    content: "";
    width: 100%;
    height: 100%;
    background-color: $overlay;
    border-radius: 50%;
    z-index: -1;
    animation: ripple 1.5s ease-out infinite;
}

.innerDiv {
    position: absolute;
    width: 135.5px;
    height: 41.86px;
    font-family: "ITC Avant Garde Gothic Demi";
    font-style: normal;
    font-weight: bolder;
    font-size: 19px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    color: $white;
}

.or {
    display: block;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: $gray1;
    text-align: center;
}

.manualVenue {
    display: block;
    font-weight: bold;
    font-size: 15px;
    color: $primary_blue;
    text-decoration: underline;
}

.ma {
    display: block;
    text-align: center;
}

@keyframes ripple {
    from {
        opacity: 1;
        transform: scale(1);
    }
    to {
        opacity: 0;
        transform: scale(1.8);
    }
}
