$them_color: #000;
$gray1: #808080;
$white: #fff;
$black: #000;
$grayTwo: #dedede;
$counterBack: #ff6600;
$whiteOne: "#f4f4f4";
$green: "green";
$redOne: "#ea422b";
$grayThree: "#888888";
$mutedText: #dbd6d6;
$greyText: #636363;
$mutedGrey: #afafaf;
$primary_blue: rgb(23, 30, 132);
$lightBlue: rgba(23, 30, 132, 0.08);
$shadow: rgba(0, 0, 0, 0.2);
$overlay: rgba(0, 0, 0, 0.25);
$border: #e9e9ea;
$border2: #c4c4c4;
$cancelBtn: #c40000;
$lightRed: rgba(196, 0, 0, 0.08);
$badge: #696969;
$date: #505050;
$sucess: #2d981c;
$correct: #15a415;
$wrong: #ea4025;
$darkGreen: #2d981c14;
$greyText2: #afafaf;
$timeBadge: #ea4025;
$lightGold: #e2901b;
$lighterGold: #f0dbc1;
$goldBackground: rgba(227, 146, 26, 0.2);
$error: #df4759;
$sucess_highlight: rgba(26, 205, 26, 0.07);
$error_highlight: rgba(243, 57, 80, 0.07);
$purple: #7988ff;
