.socialLogin {
    text-align: center;
    color: gray;
    margin-bottom: 14px;
}
.log {
    font-size: xx-large;
}
.flex-container {
    // -webkit-box-shadow: 0px 5px 33px -21px rgba(66, 68, 90, 1);
    // -moz-box-shadow: 0px 5px 33px -21px rgba(66, 68, 90, 1);
    // box-shadow: 0px 5px 33px -21px rgba(66, 68, 90, 1);
    display: flex;
    align-items: center;
    flex-direction: row;
    color: white;
    justify-content: left;
    margin-bottom: 20px;
    // border-radius: 20px;
}

.flex-container > span {
    padding: 10px;
    font-size: 30px;
}

.loginButton1 {
    padding: 15px 25px;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
}

.google {
    background-color: #df4930;
}
.facebook {
    background-color: #507cc0;
}
.github {
    background-color: black;
}

.phone {
    background-color: #b939af;
}
.instagram {
    background-color: #df4930;
}

.i {
    // display: block;
    border: 0px;
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 1px 2px;
    color: rgb(255, 255, 255);
    cursor: pointer;
    font-size: 19px;
    overflow: hidden;
    padding: 10px;
    user-select: none;
    background: linear-gradient(to right, rgb(236, 146, 35) 0%, rgb(177, 42, 160) 50%, rgb(105, 14, 224) 100%);
}
