@import "../../commons/colors.scss";

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 50%;
}

.load {
  color: $gray1;
}
