@import "./../../commons/fontsizes.scss";

.heading {
    text-align: center;
    margin-top: 20px;
    font-size: $xxlarge;
}

.inpu {
    padding: 21px;
}
