@import "../../commons/fontfamily.scss";
@import "../../commons/colors.scss";

.checkout-info {
	height: 100vw;
}

.inputContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	padding: 20px 20px;
}
.Row {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	padding: 0px 0px;
	width: 89%;
	margin-left: 20px;
	margin-top: -25px;
}
.in {
	width: 90%;
}
.checkout {
	font-family: $itc;
	color: $gray1;
	font-weight: bold;
	font-size: 16px;
	border-bottom: 1px solid $gray1;
	width: 80px;
	margin-left: 20px;
}
.paymentInfo {
	font-family: $itc;
	font-size: 11px;
	font-weight: bold;
	margin-bottom: -9px;
	margin-left: 5px;
}
.paymentContainer {
	padding-left: 23px;
}

.cardInfo {
	padding-left: 23px;
	margin-top: 25px;
}

.innerDiv {
	display: flex;
	justify-content: center;
	align-items: center;
}
.lable {
	font-family: $itc;
	font-size: 9px;
	margin-left: 5px;
}
.check {
	display: flex;
	align-items: center;
	margin-top: 5px;
	margin-left: 20px;
}

.total {
	font-size: 12px;
	font-family: $itc;
	height: 5px;
	margin-left: 25px;
}
.span {
	font-weight: bold;
	margin-right: 4px;
}

.dateContainer {
	width: 40%;
	margin-right: 20px;
}
.CvcContainer {
	width: 40%;
	margin-left: 20px;
}
.bottomContainer {
	border-top: 1px solid $gray1;
	height: 100px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.bottomContainerTwo {
	height: 200px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.btn {
	width: 90%;
	height: 46px;
	background-color: $them_color;
	outline: none;
	border: none;
	color: $white;
	text-transform: uppercase;
	font-family: itc;
	font-weight: bolder;
	align-items: center;
	justify-content: center;
	display: flex;
	border-radius: 3px;
}
.priceTotal {
	font-family: $itc;
	font-weight: bold;
	font-size: 12px;
	text-transform: uppercase;
	line-height: 0px;
}
.tagline {
	display: flex;
	align-items: center;
	font-size: 12px;
	color: $gray1;
	margin-top: 10px;
}
.paypal {
	font-family: $itc;
	font-weight: bold;
	font-size: 12px;
	text-transform: uppercase;
	margin-left: 23px;
}

.securityIcon {
	color: $them_color;
	margin-right: 10px;
}
.buttonContainer {
	position: absolute;
	z-index: -1;
	width: 100%;
	display: flex;
	justify-content: center;
	margin-top: 35px;
}
.wait {
	display: none;
}
.want_end {
	display: flex;
	align-items: center;
	justify-content: center;
	// margin-top: 120px;
	height: 100%;
	// border: 1px solid;
	position: absolute;
	top: 0;
	width: 100%;
}

.btn1 {
	width: 80%;
	height: 46px;
	background-color: $counterBack;
	outline: none;
	border: none;
	color: $white;
	text-transform: uppercase;
	font-family: itc;
	font-weight: bolder;
	align-items: center;
	justify-content: center;
	display: flex;
	border-radius: 3px;
}