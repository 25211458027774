@import "../../commons/colors.scss";
@import "../../commons/fontfamily.scss";
@import "../../commons/fontsizes.scss";

.mainDiv {
  height: 105px;
  display: flex;
  box-shadow: 0px 3px 15px $gray1;
  border-radius: 5px;
  background-color: $white;
}
.mainDivQ {
  height: 85px;
  display: flex;
  box-shadow: 0px 3px 15px $gray1;
  border-radius: 5px;
  background-color: $white;
}
.imageContainerSS {
  width: 30%;
  border-right: 1px solid $gray1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 83px;
  margin-top: 10px;
}
.texts {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-left: 10px;
}
.quantities {
  display: flex;
  flex-direction: column;
  font-size: $normal;
  width: 15%;
  text-align: right;
}

.name {
  font-size: $small;
  letter-spacing: 0px;
  font-weight: bolder;
  margin-top: 12px;
}

.sub {
  font-size: $small;
  letter-spacing: 0.1px;
  line-height: 0cm;
  color: $black;
}

.lH {
  line-height: 12px;
}

.descriptions {
  padding-bottom: 10px;
  height: 55px;
  overflow-y: auto;
  margin: 2px;
}
.opt {
  color: #000;
  font-weight: bold;
  font-size: $small;
}

.upperrate {
  margin-top: 9px;
  margin-bottom: 36px;
  color: $gray1;
  font-weight: bold;
}
.totalRate {
  font-weight: bold;
}
.totalRatewithQuantity {
  font-weight: bold;
  margin-top: 55px;
}

.iconsSetting {
  display: flex;
  align-items: center;
  margin-top: 4px;
}

.pen {
  color: $them_color;
  margin-left: 4px;
  margin-top: 2px;
}

.bottombar {
  margin-left: 20px;
  margin-bottom: 0px;
  margin-top: 0px;
  color: $them_color;
  width: 100px;
  background-color: $them_color;
  border-color: $them_color;
}
.catagories {
  width: 40%;
}
.cross {
  width: 20px;
  margin-top: -10px;
  margin-right: -10px;
  height: 25px;
}
.subIns {
  margin-block-start: -5px;
  margin-block-end: 0;
  font-size: $xtiny;
  color: $gray1;
  width: 200px;
  word-wrap: break-word;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
