@import "../../commons/fontfamily.scss";
@import "../../commons/colors.scss";

.sub-header {
	margin-top: 16%;
}

.alignment{
	margin-top: 21%!important;
    margin-left: 27%!important;
}

.rewardsContainer {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
}

.below {
	z-index: -1;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
}

.points {
	position: absolute;
	top: 0;
	color: $white;
	font-weight: 600;
	display: flex;
	flex-direction: column;
	margin-top: 2.85rem;
	margin-left: 0.75rem;
	.title {
		font-size: 1.35rem;
	}

	div {
		display: flex;
		align-items: center;
		padding: 0.5rem 0rem;
		.coin {
			width: 40%;
			padding: 0.65rem;
			background: rgba(255, 255, 255, 0.25);
			border-radius: 0.85rem;
		}
		.totalPoints {
			padding: 0rem 0.5rem;
			font-size: 1.25rem;
		}
	}
}

.rewards {
	margin: 0.95rem;
	.listTitle {
		width: 100%;
		display: flex;

		span {
			text-align: center;
			width: 100%;
			padding-bottom: 0.5rem;
			font-weight: 600;
			font-size: 0.95rem;
		}

		.rewardsTitle {
			border-bottom: 3px solid $border;
			border-radius: 1px 0px 0px 1px;
		}

		.historyTitle {
			border-bottom: 3px solid $primary_blue;
			border-radius: 0px 1px 1px 0px;
		}
	}
}

@media only screen and (max-width: 360px) {
	.points {
		.title {
			font-size: 1rem !important;
		}

		div {
			.totalPoints {
				font-size: 1rem;
			}
			.coin {
				width: 30%;
			}
		}
	}
}

@media only screen and (min-width: 930px) {
	.headerSVG {
		width: 70%;
	}

	.points {
		margin-top: 10rem !important;
	}
}

@media only screen and (min-width: 400px) {
	.points {
		margin-top: 4rem;
	}
}

@media only screen and (min-width: 460px) {
	.points {
		margin-top: 4.5rem;
	}
}

@media only screen and (min-width: 500px) {
	.points {
		margin-top: 6rem;
	}
}

@media only screen and (min-width: 600px) {
	.points {
		margin-top: 8rem;
	}
}

@media only screen and (min-width: 700px) {
	.points {
		margin-top: 10rem;
	}
}

@media only screen and (min-width: 800px) {
	.points {
		margin-top: 14rem;
	}
}
