@import '../../commons/colors.scss';
@import '../../commons/fontfamily.scss';

.blur {
  background-color: #d3d3d3;
  height: 100vh;
}

.listOne {
  list-style: none;
  margin-top: 20px;
  margin-left: 10px;
  padding: 0px;
}
.listOne li {
  display: inline-block;
  font-family: itc;
  font-size: 3.5vw;
  margin-bottom: 0px;
  color: $them_color;
  padding-left: 4px;
}

.homeText {
  color: $them_color;
}
.names {
  display: flex;
  align-items: center;
  margin-left: 15px;
}
.sections {
  margin-top: 5px;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.sectionsTwo {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.ReactModal__Overlay {
  opacity: 0;
  transform: translateX(-100px);
  transition: all 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transform: translateX(0px);
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  transform: translateX(-100px);
}

.miniLoader {
  display: flex;
  align-items: center;
  justify-content: center;
}
.alertStyle {
  position: fixed;
  top: 80%;
  width: 80%;
  transform: translate(-50%, -50%);
}
.overlayStyles {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: scroll;
  display: block;
  background-color: rgba(179, 169, 169, 0.301);
}
.seat-info {
  justify-self: end;
  margin: 10px 10px 0 0;
  border: 1px solid;
  width: 100px;
  height: 56px;
}
.seat-info > p {
  text-align: center;
  font-size: 14px;
  line-height: 0;
}

.loaderOne {
  display: flex;
  align-items: center;
  justify-content: center;
  // margin-top: 120px;
  position: absolute;
  width: 100%;
  height: 50%;
}
