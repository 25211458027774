@import "../../commons/colors.scss";
@import "../../commons/fontfamily.scss";
@import "../../commons/fontsizes.scss";

.inputStyle {
  color: $gray1;
  font-family: itc;
  height: 44px;
  padding: 8px;
  margin-left: -10px;
  font-size: $normal;
  padding-left: 20px;
}
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: $gray1;
  font-size: 10px;
  font-style: normal;
}

input:focus {
  outline: none;
}
textarea:focus {
  outline: none;
}
.imageContainer {
  background-color: $green;
}
.img {
  background-color: $white;
  margin-top: -25px;
  height: 22px;
  width: 25px;
  margin-left: 10px;
  textarea:focus,
  input:focus {
    outline: none;
  }
}

.MainContainer {
  height: 24px;
  width: 92%;
  padding: 10px 10px;
  display: flex;
  flex-direction: column;
  border: 2px solid $black;
  margin-bottom: 35px;
}
.submit {
  background-color: $white;
}
