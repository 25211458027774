@import "../../commons/colors.scss";
@import "../../commons/fontfamily.scss";
@import "../../commons/fontsizes.scss";

.redeemOrderContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pending-text-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.pending-text {
  justify-content: center;
  width: "20%";
  text-align: center;
  margin-bottom: 0.5rem;
  font-family: $itc;
  font-size: $normal;
  font-weight: 400;
  line-height: 12.37px;
}

.points-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $goldBackground;
  border-radius: 1rem;
  height: 30px;
  color: $lightGold;
  margin: 1rem 0rem;
  img {
    width: 28%;
    margin-top: 0.2rem;
    margin-right: 0.2rem;
  }
}

.confirmBtn,
.item-name {
  font-weight: 600;
}

.confirmBtn {
  background: #171e84;
  padding: 0.25rem 0.65rem;
  color: #ffff;
  border-radius: 0.75rem;
  width: 90%;
  text-align: center;
}
