.PhoneInputInput{
    border: 1px solid red!important;
}

.PhoneInputCountry{
    position: absolute;
    margin-left: 9px;
    margin-top: 10px;

}

.PhoneInput{
    width: 100%;
}

.inp input{
    padding: 0.5rem 3.25rem;
}