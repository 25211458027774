@import "../../commons/colors.scss";

.OuterBox {
  width: 300px;
  height: 230px;
  border-radius: 10px;
  border: 1px solid $them_color;
  background-color: $white;
}
.inputs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.inputs label {
  font-family: itc;
}
.inputdiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
}
.inputdiv input {
  border: none;
  border-bottom: 1px solid $them_color;
  outline: none;
}
.button {
  background-color: $them_color;
  color:$white;
  border: none;
  width: 90px;
  height: 40px;
  margin-top: 10px;
  border-radius: 14px;
  font-family: itc;
}
