@import "../../commons/fontfamily.scss";
@import "../../commons/colors.scss";

.toast {
	background: $white;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
	flex-direction: row;
	font-size: 10px;
	padding: 0.5rem;
	width: 70%;
	border-radius: 1.5rem;
	animation: toastAnimate 0.85s forwards;

	.exit {
		margin: auto 0.5rem auto auto;
		width: 8px;
	}

	.status {
		margin: 0;
		font-weight: 700;
	}

	.toast-text {
		padding: 0.25rem 0.5rem;
		span {
			color: $greyText2;
		}
	}
}

@media only screen and (max-width: 360px) {
	.toast {
		width: 90%;
	}
}

@keyframes toastAnimate {
	0% {
		top: -100%;
	}
	100% {
		top: 10px;
	}
}
