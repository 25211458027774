@import "../../commons/fontfamily.scss";
@import "../../commons/colors.scss";
.questionContainer {
  padding: 0.5rem;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
  .question-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .contest-name {
      img {
        width: 0.95rem;
        padding: 0rem 0.25rem;
      }
      span {
        font-size: 12px;
        font-weight: 600;
      }
    }

    .win-points {
      padding: 0.25rem;
      border-radius: 0.35rem;
      img {
        width: 1.5rem;
        padding: 0rem 0.25rem;
      }

      span {
        font-size: 10px;
      }
      .points-quantity {
        font-weight: 700;
        font-size: 12px;
      }

      border: 1px solid $border2;
    }

    .time-badge {
      background: $timeBadge;
      font-size: 10px;
      color: $white;
      text-align: center;
      padding: 0.45rem;
      border-radius: 0.85rem;
      margin-left: 0.3rem;
    }

    .contest-name,
    .win-points {
      display: flex;
      align-items: center;
    }
  }

  .question {
    font-size: 12px;
    padding: 1rem 0rem;
  }

  .answer {
    font-size: 12px;

    ul {
      margin-block-end: 0rem;
      margin-block-start: 0rem;
      padding-inline-start: 0rem;

      input {
        display: none;
      }

      li {
        display: inline-block;
        margin: 0.5rem 0.25rem;
      }

      label {
        display: flex;
        justify-content: center;
        color: $primary_blue;
        font-weight: 700;
        background: $white;
        padding: 0.25rem 0.5rem;
        box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.15);
        border-radius: 1rem;
        transition: background-color 0.3s ease;
        cursor: pointer;
        box-sizing: border-box;
      }

      input:checked + label {
        color: $white;
        background: $primary_blue;
      }
    }
  }

  .bold {
    font-weight: 700;
  }
}

.answer-badges {
  display: flex;
  flex-direction: column;
}
.list-Style {
}

.submitBtn {
  background: $primary_blue;
  padding: 0.4rem;
  margin-top: 0.8rem;
  color: $white;
  width: 100%;
  border: none;
  font-size: 15px;
  border-radius: 1rem;
}























.pagination {
  justify-content: center;
}

ul {
  list-style: none;
  padding: 0;
}

ul.pagination li {
  display: inline-block;
  width: 30px;
  border: 1px solid #e2e2e2;
  display: flex;
  justify-content: center;
  font-size: 25px;
}

ul.pagination li a {
  text-decoration: none;
  color: #337ab7;
  font-size: 20px;
}

ul.pagination li.active a {
  color: white;
}
// ul.pagination li.active {
//   background-color: #337ab7;
// }

// ul.pagination li.active {
//   background-color: #337ab7;
// }

// ul.pagination li a:hover,
// ul.pagination li a.active {
//   color: blue;
//   background-color: #337ab7;
// }

.grey{
  color: gray;
  background-color: rgb(201, 201, 201);
}
.ac{

  color: white!important;
  background-color: #337ab7;
}

.page-selection {
  width: 48px;
  height: 30px;
  color: #337ab7;
}

.pagination-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}