@import "../../commons/colors.scss";

.overlay {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 2;
	width: 100%;
	height: 100vh;
	background-color: $overlay;
	display: flex;
	justify-content: center;
	align-items: center;
}

.modal {
	background: $white;
	width: 70%;
	padding: 1rem;
	border-radius: 1.25rem;
	box-shadow: 0 0 3rem 1vmax $shadow;
	display: flex;
	flex-direction: column;
	.cross-icon {
		align-self: end;
		cursor: pointer;
		font-size: 0.65rem;
		justify-self: flex-end;
	}
}
