@import "../../commons/colors.scss";
.rectangle {
  /* Rectangle 26 */
  display: inline-block;
  margin: 15px;
  width: 80%;
  height: 50px;
  background: $white;
  box-shadow: 0px 2px 8px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.rewardWriting {
  /* $5 Off Snack Bar */
  height: 100%;
  font-family: "ITC Avant Garde Gothic Demi";
  font-style: normal;
  font-weight: 900;
  font-size: 22px;
  line-height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  color: $black;
}

/* The container */
.container11 {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.container11 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 24px;
  left: 15px;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background: $mutedText;
}

/* On mouse-over, add a grey background color */
.container11:hover input ~ .checkmark {
  background-color: $greyText;
}

/* Show the indicator (dot/circle) when checked */
.container11 input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container11 .checkmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: $white;
}
